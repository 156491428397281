// Tile.js
import React, { useEffect, useState } from 'react';

const Tile = ({ initialValue, onRotate, style }) => {
  const [rotation, setRotation] = useState(0); // Хранение угла поворота

  // Высчитываем позицию трубы в зависимости от поворота для спрайта
  const absRotation = (type, rotation) => {
    switch (type) {
      case 0: return 0;
      default: return rotation / 90 % 4;
    }
  }

  const getImage = (type, rotation) => {
    const images = [
      {0: `/images/game/empty.svg`},
      {
        0: `/images/game/${color}/turn-3.svg`,
        1: `/images/game/${color}/turn.svg`,
        2: `/images/game/${color}/turn-2.svg`,
        3: `/images/game/${color}/turn-4.svg`
      },
      {
        0: `/images/game/${color}/straight.svg`,
        1: `/images/game/${color}/straight-2.svg`,
        2: `/images/game/${color}/straight-2.svg`,
        3: `/images/game/${color}/straight.svg`,
      },
      {
        0: `/images/game/${color}/t-pipe.svg`,
        1: `/images/game/${color}/t-pipe-2.svg`,
        2: `/images/game/${color}/t-pipe-2.svg`,
        3: `/images/game/${color}/t-pipe.svg`,
      },
      {
        0: `/images/game/${color}/plus.svg`,
        1: `/images/game/${color}/plus-2.svg`,
        2: `/images/game/${color}/plus-3.svg`,
        3: `/images/game/${color}/plus-4.svg`,
      },
      {
        0: `/images/game/${color}/plus-green.svg`,
        1: `/images/game/${color}/plus-green-2.svg`,
        2: `/images/game/${color}/plus-green-3.svg`,
        3: `/images/game/${color}/plus-green-4.svg`,
      },
      {
        0: `/images/game/${color}/plus-blue.svg`,
        1: `/images/game/${color}/plus-blue-2.svg`,
        2: `/images/game/${color}/plus-blue-3.svg`,
        3: `/images/game/${color}/plus-blue-4.svg`,
      },
      {
        0: `/images/game/${color}/plus-purple.svg`,
        1: `/images/game/${color}/plus-purple-2.svg`,
        2: `/images/game/${color}/plus-purple-3.svg`,
        3: `/images/game/${color}/plus-purple-4.svg`,
      },
      {
        0: `/images/game/${color}/plus-red.svg`,
        1: `/images/game/${color}/plus-red-2.svg`,
        2: `/images/game/${color}/plus-red-3.svg`,
        3: `/images/game/${color}/plus-red-4.svg`,
      },
      {0: `/images/game/empty.svg`},
      {
        0: `/images/game/${color}/in.svg`,
        1: `/images/game/${color}/in-2.svg`,
        2: `/images/game/${color}/in-2.svg`,
        3: `/images/game/${color}/in.svg`,
      },
      {
        0: `/images/game/${color}/turn-green-3.svg`,
        1: `/images/game/${color}/turn-green.svg`,
        2: `/images/game/${color}/turn-green-2.svg`,
        3: `/images/game/${color}/turn-green-4.svg`,
      },
      {
        0: `/images/game/${color}/turn-blue-3.svg`,
        1: `/images/game/${color}/turn-blue.svg`,
        2: `/images/game/${color}/turn-blue-2.svg`,
        3: `/images/game/${color}/turn-blue-4.svg`,
      },
      {
        0: `/images/game/${color}/turn-purple-3.svg`,
        1: `/images/game/${color}/turn-purple.svg`,
        2: `/images/game/${color}/turn-purple-2.svg`,
        3: `/images/game/${color}/turn-purple-4.svg`,
      },
      {
        0: `/images/game/${color}/turn-red-3.svg`,
        1: `/images/game/${color}/turn-red.svg`,
        2: `/images/game/${color}/turn-red-2.svg`,
        3: `/images/game/${color}/turn-red-4.svg`,
      }
    ]
    switch (type) {
      default: return images[type][`${absRotation(type, rotation)}`];
      case 50: return images[0][0];
    }
  }

  const color = initialValue[2]
  // const color = "blue"

  useEffect(() => {
    if (initialValue.length > 1) {
      setRotation(initialValue[1]);
    }
  }, []);

  // Отправляет в PipeGame информацию о том что труба была повернута, чтобы провести все необходимые проверки
  useEffect(() => {
    onRotate(rotation);
  }, [rotation]);

  const handleClick = (e) => {
    // Если тип трубы не поддерживает поворот
    if (initialValue[0] === 0) return;
    if (initialValue[0] === 10) return;

    // Поворачиваем трубу
    let currentRotation = rotation;
    if (e.type === 'click') {
      setRotation(currentRotation + 90);
    } else {
      prevent(e);
      setRotation(currentRotation - 90);
    }
  };

  const prevent = (e) => {
    e.preventDefault();
  }

  return (
    <div className="cell" onClick={handleClick} onContextMenu={handleClick} >
      {/* <img src={images[0]} alt={`Pipe ${initialValue}`} className="pipe-bg" draggable="false"/> */}
      {(initialValue[0] !== 50) && <img src={getImage(initialValue[0], rotation)} alt={`Pipe ${initialValue}`} className="pipe-image" draggable="false" style={{ transform: `rotate(${rotation}deg)` }} />}
    </div>
  );
};

export default Tile;

import React, { useState } from "react";
import { Link, useLocation, useNavigate } from 'react-router-dom';

const Header = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const [balance, setBalance] = useState(100);

    const menuIcon = () => {
        switch (location.pathname) {
            case "/": return (<Link to="/"><img src="/images/game/icons/menu-button.png" alt="menu-button"></img></Link>);
            case "/game": return (<Link to="/"><img src="/images/game/icons/return-arrow.svg" alt="menu-button"></img></Link>);
            default: return (<Link onClick={() => {navigate(-1)}}><img src="/images/game/icons/return-arrow.svg" alt="menu-button"></img></Link>);
        }
    }

    return (
        <header>
            <div className="container e-flex e-flex_jb">
                <div className="e-flex">
                    <div className="e-flex button">
                        {menuIcon()}
                    </div>
                </div>
                <div className="e-flex e-flex_ac stat"><img src="/images/game/coins.png" height={50} alt="coin"></img> <p>{balance}</p></div>
            </div>
        </header>
    )
}

export default Header;
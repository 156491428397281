import React from "react";
import MenuButton from "./elements/MenuButton";

const MainPage = () => {
    return (
        <div className="e-flex e-flex_col e-flex_center MainPage">
            <MenuButton title="Играть" link="/map"/>
            <MenuButton title="Статистика" />
            <MenuButton title="Настройки" />
        </div>
    );
}

export default MainPage;
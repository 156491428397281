import React, { useState } from "react";

const Footer = () => {

    const [balance, setBalance] = useState(100);

    return (
        <footer>
            <div className="container e-flex e-flex_jb">
                <div className="e-flex button">
                    <img src="/images/game/icons/prize-cup.png" alt="profile"></img>
                </div>
                <div className="e-flex button">
                    <img src="/images/game/icons/gift.png" alt="profile"></img>
                </div>
            </div>
        </footer>
    )
}

export default Footer;
import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import '../css/App.css';
import MenuButton from './elements/MenuButton';
import { useDispatch, useSelector } from 'react-redux';
import { setWin, setLevel } from '../store/store';

const WinPage = () => {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const level = useSelector(state => state.main.game.level);
    const win = useSelector(state => state.main.game.win);

    return (
        <div className={`WinPage ${(win) ? "fade-in" : ""} e-flex e-flex_col`}>
            <div className='e-flex e-flex_col e-flex_jb card'>
                <h2>Победа!</h2>
                <div className='e-grid'>
                    <MenuButton title={">>>"} onClick={() => {
                        dispatch(setWin(false)); 
                        dispatch(setLevel(level+1)); 
                        navigate("/game/" + Number(+level+1))
                    }} />
                </div>
            </div>
        </div>
    );
}

export default WinPage;
import React, { useEffect, useState } from "react";
import PipeGame from './game/PipeGame'
import { useParams } from "react-router-dom";
import WinPage from "./WinPage";
import { useSelector } from "react-redux";

const GamePage = () => {

    const params = useParams();
    const level = params.id;
    const [lvl, setLvl] = useState(null);
    // Callback победы
    const win = useSelector(state => state.main.game.win)

    useEffect(() => {
        console.log("WIN? " + win)
    }, [win])

    // Загружаем уровень
    useEffect(() => {
        if (level) fetch('/levels/' + level + '.json')
        .then(data => data.json())
        .then(lvl => {
            setLvl(lvl);
        })
    }, [level]);

    const getLvlMode = (mode) => {
        switch (mode) {
            case "easy": return "Легкий";
            case "medium": return "Средний";
            case "hard": return "Сложный";
            default: return "";
        }
    }

    return (level) && (
        <>
            <div className="e-flex e-flex_col GamePage">
                <h2>Уровень {level}</h2>
                <h3>{ (lvl) && getLvlMode(lvl.mode) }</h3>

                <PipeGame level={level} />
            </div>
            <WinPage />
        </>
    );
}

export default GamePage;
import Header from "./components/layout/Header";
import Footer from "./components/layout/Footer";
import Layout from "./components/layout/Layout";

function App() {
  return (
    <div className="App e-flex_col e-flex_jb">
      <Header />
      <Layout />
      <Footer />
    </div>
  );
}

export default App;
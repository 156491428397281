import React, { useEffect, useState } from "react";
import MenuButton from "./elements/MenuButton";
import { useParams } from "react-router-dom";

const LevelsPage = () => {

    const params = useParams();
    const mode = params.mode;
    const [levels, setLevels] = useState(null);

    const getLevels = async (mode) => {
        let range = [];
        let levels = [];
        switch (mode) {
            case "easy": range = [1, 15]; break;
            case "medium": range = [16, 25]; break;
            case "hard": range = [26, 35]; break;
            default: break;
        }
        for (let i = range[0]; i <= range[1]; i++) {
            try {
                const lvl = await fetch(`/levels/${i}.json`)
                    .then(data => data.json())
                    .then(data => {
                        console.log(i);
                        levels.push(data);
                        console.log(levels);
                    })
                    .catch(err => console.log(err));
            } catch (err) {
                console.log(err)
            }
        }
        setLevels(levels);
    }

    useEffect(() => {
        if (mode) {
            getLevels(mode);
        }
    }, [mode]);

    // Проверяем выбрана ли сложность, если нет предлагаем выбрать
    return (!mode) ? (
        <div className="e-flex e-flex_col e-flex_center LevelsPage">
            <MenuButton title="Легкая" link="/map/easy" />
            <MenuButton title="Средняя" link="/map/medium" />
            <MenuButton title="Сложная" link="/map/hard" />
        </div>
    ) : (
        <div className="e-flex e-flex_col e-flex_ac LevelsPage">
            {
                (Array.isArray(levels)) && levels.map((lvl) => {
                    console.log(111)
                    return (
                        <MenuButton key={lvl.level} title={"Уровень " + lvl.level} link={"/game/" + lvl.level} />
                    )
                })
            }
        </div>
    )
}

export default LevelsPage;
import { configureStore, createSlice } from '@reduxjs/toolkit'

const mainSlice = createSlice({
    initialState: {
      game: {
        win: false,
        level: null
      }
    },
    name: "main",
    reducers: {
      setLevel: (state, action) => {
        state.game.level = action.payload;
      },
      setWin: (state, action) => {
        state.game.win = action.payload;
      },
    }
});

// Экспортируем действия
export const { setLevel, setWin } = mainSlice.actions;

// Настройка хранилища
const store = configureStore({
  reducer: {
    main: mainSlice.reducer
  },
});

export default store;